//
//  POST PREVIEW / CARD
//

import React from "react"

import Card from "react-bootstrap/Card"
import Link from "gatsby-link"

const PostCard = (props) => (
            <div>
                <Card>
                    <div>{props.postImage}</div>
                    <Card.Body>
                        <Card.Title><h3>{props.cardTitle}</h3></Card.Title>
                        <i className="card-date">{(() => {
                                const dateString = props.postDate.split("/")
                                const Month = dateString[0]
                                dateString.splice(2,0, Month)
                                dateString.splice(0,1)
                                const dateOut = dateString.join(".")
                                return dateOut
                                })()}</i>
                        <p>{props.postExcerpt}</p>
                        <Link className="card-link" to={props.linkToPost}>zum Post</Link>
                    </Card.Body>
                </Card>
            </div>
        )


export default PostCard