import React, { Component } from "react" 
import { Helmet } from "react-helmet"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Header from "../header"
import Sidebar from "../sidebar"
import Img from "gatsby-image"
import PostCard from "./post-card"

class BlogLayout extends Component {

    state = {
        sidebarHide: true,
        sidebarClass: 'sidebar',
    }

    handleToggle = () => {
        console.log(this.state.sidebarHide)
        this.setState((prevState) => {
            if(prevState.sidebarHide){
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar sidebar-show-mobile',
                }
            } else {
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar',
                }
            }
            
        })
    }

    render() {
        
        let featuredImage
    
        if(this.props.featuredImage != null) {
            featuredImage = <Img fluid={this.props.featuredImage} />
        }

        console.log(this.props.pageContent)

        return (
            <div className="main" style={{ height: '100%' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Ines Puettcher Pflege</title>
                </Helmet>
                <Header clickFunction={this.handleToggle} menuState={this.state.sidebarHide} /> 
                <div className="wrapper">
                    <Sidebar sidebarClass={this.state.sidebarClass} />
                    <Container fluid className="mainCol">
                        <Row>
                            <Col>
                            <div className="content">
                            <h1>Blog</h1>
                                {this.props.pageContent.map((entry)=> (
                                    <PostCard className="PostCard" 
                                        cardTitle={entry.node.frontmatter.title}
                                        postDate={entry.node.frontmatter.date}
                                        linkToPost={entry.node.fields.slug}
                                        postImage={featuredImage}
                                        postExcerpt={entry.node.excerpt}
                                    />
                                ))}
                                               </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default BlogLayout