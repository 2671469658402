import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../components/blog/blog-layout"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/style.scss"

export default function BlogTemplate(data) {
    const pageData = data.data.allMarkdownRemark.edges
    return <BlogLayout pageContent={pageData} />
}

export const query = graphql`query{
  allMarkdownRemark (filter: {frontmatter:{type: {eq: "post"}}}){
    edges {
      node{
        fields {
          slug
        }
      excerpt
      html
      frontmatter {
        title
        date
        position
        date
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}`